<template>
<!-- chat-area -->
<div class="chat-area">
    <!-- message-list -->
    <div class="message-list">
        <div class="modal-dialog-scrollable">
            <div class="modal-content">
                <div class="msg-search">
                    <input type="text" class="form-control" id="ChatListFilterSearch" placeholder="Search" aria-label="search" @keyup="ChatListFilterSearch" />
                    <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
                    <div class="add-msg2">
                        <div class="navbar dropdown">
                            <a class=" dropdown-toggle" href="#" role="button" id="dropdownMenuLink2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="img-fluid" src="@/assets/img/sort.svg" alt="image title">
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink2">
                                <a :class="{'dropdown-item':true,'active':Params?.params.type == '','active':Params?.params.type == 'all'}" :href="'/inbox/all'">All</a>
                                <a :class="{'dropdown-item':true,'active':Params?.params.type == 'unread'}" :href="'/inbox/unread'">Unread (<small>{{ unreadChatCount }}</small>)</a>
                                <a :class="{'dropdown-item':true,'active':Params?.params.type == 'unreplied'}" :href="'/inbox/unreplied'">Unreplied (<small>{{ unrepliedChatCount }}</small>)</a>
                                <a v-for="Tag in AllTagList" v-bind:key="Tag.id" :class="{'dropdown-item':true,'active':Params?.params.type == Tag.slug}" :href="'/inbox/'+Tag.slug">{{ Tag.tag }} (<small>{{ Tag.customer_count }}</small>)</a>
                            </div>
                        </div>
                    </div>
                    <div class="add-msg">
                        <a href="javascript:;" data-dismiss="modal" aria-label="Close" @click="sendSMSFromMessage">
                            <img class="img-fluid" src="@/assets/img/add.svg" alt="image title" />
                        </a>
                    </div>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button class="nav-link active" id="Open-tab" data-toggle="tab" data-target="#Open" type="button" role="tab" aria-controls="Open" aria-selected="true">Active<i v-if="unreadChatCountActive > 0" class="fa-solid fa-circle fa-beat text-danger"></i></button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button class="nav-link" id="Closed-tab" data-toggle="tab" data-target="#Closed" type="button" role="tab" aria-controls="Closed" aria-selected="false">In-Active<i v-if="unreadChatCountInActive > 0" class="fa-solid fa-circle fa-beat text-danger"></i></button>
                        </li>
                    </ul>
                </div>

                <!-- chat-list -->
                <div class="chat-list modal-body" id="ChatWindowActive" ref="ChatWindowActive">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="Open" role="tabpanel" aria-labelledby="Open-tab">
                            <div class="chat-list">
                                <a v-for="(ChatItem, activeIndex) in AllActiveChats" v-bind:key="ChatItem.id" :class="'all-list all-chat-list-active chat-list-item chat-no-'+ChatItem.id" :data-index="activeIndex" href="javascript:;" @click="getChatMessages(ChatItem.id,'active')">
                                    <!-- :href="'/inbox/'+Params?.params.type+'/'+ChatItem.id" -->
                                    <div v-if="ChatItem.customer" :class="{ 'media': true, 'active': Params?.params?.chat_id == ChatItem.id }" :data-customer_name="(ChatItem.customer.first_name+' '+ChatItem.customer.last_name).toLowerCase()" :data-chat_id="ChatItem.id" @click="showMessageBox">
                                        <img src="@/assets/img/Oval2.svg" class="img-fluid mr-3" alt="..." style="display:none;">
                                        <div class="media-body">
                                            <h5 :class="'stars-'+ChatItem.id"><a @click.stop="starClick(ChatItem.id, 'active')" :class="{ 'stars': true,  'important': ChatItem.is_pinned == 1 }" href="javascript:;" :data-id="ChatItem.id" data-type="active"><i class="fa fa-star" aria-hidden="true"></i></a> {{ ChatItem.customer.first_name+' '+ChatItem.customer.last_name }} <span>{{ ChatItem.chat_time }}</span></h5>
                                            <p>{{ ChatItem.new_message }}</p>
                                            <div class="listbottom">
                                                <p class="fb">Active</p>
                                                <p v-if="ChatItem.is_read == 0"><span>{{ ChatItem.new_message_count }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <div class="text-center loading-chat-list">
                                    <img src="@/assets/img/loading.gif" class="img-fluid" style="width:80px;" />
                                    <br>
                                    loading...
                                </div>
                                <p class="chat-list-empty-active" style="text-align:center; padding:10px 0; display:none;">No User found.</p>
                                <p class="text-center no-chat-list-active" v-if="AllActiveChats.length == 0">No conversations found </p>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="Closed" role="tabpanel" aria-labelledby="Closed-tab">
                            <div class="chat-list">
                                <div class="text-center loading-chat-list">
                                    <img src="@/assets/img/loading.gif" class="img-fluid" style="width:80px;" />
                                    loading...
                                </div>
                                <a v-for="ChatItem in AllInactiveChats" v-bind:key="ChatItem.id" :class="'all-list all-chat-list-active chat-list-item chat-no-'+ChatItem.id" :data-index="activeIndex" href="javascript:;" @click="getChatMessages(ChatItem.id,'inactive')">
                                    <div v-if="ChatItem.customer" :class="{ 'media': true, 'active': Params?.params?.chat_id == ChatItem.id }" :data-customer_name="(ChatItem.customer.first_name+' '+ChatItem.customer.last_name).toLowerCase()" :data-chat_id="ChatItem.id">
                                        <img src="@/assets/img/Oval2.svg" class="img-fluid mr-3" alt="..." style="display:none;">
                                        <div class="media-body">
                                            <h5 :class="'stars-'+ChatItem.id"><a @click.stop="starClick(ChatItem.id, 'active')" :class="{ 'stars': true,  'important': ChatItem.is_pinned == 1 }" href="javascript:;" :data-id="ChatItem.id" data-type="active"><i class="fa fa-star" aria-hidden="true"></i></a> {{ ChatItem.customer.first_name+' '+ChatItem.customer.last_name }} <span>{{ ChatItem.chat_time }}</span></h5>
                                            <p>{{ ChatItem.new_message }}</p>
                                            <div class="listbottom">
                                                <p class="car">Inactive</p>
                                                <p v-if="ChatItem.is_read == 0"><span>{{ ChatItem.new_message_count }}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <p class="chat-list-empty-inactive" style="text-align:center; padding:10px 0; display:none;">No User found.</p>
                                <p class="text-center no-chat-list-inactive" v-if="AllInactiveChats.length == 0">No conversations found </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- chat-list -->
    </div>
    <!-- message-list -->

    <!-- message-box -->
    <div class="message-box">
        <div class="chats">
            <!-- start-conversation -->
            <div :class="{'conversation':true, 'hide':Params.params.chat_id}">
                <img class="img-fluid" src="@/assets/img/chat-left-dots.svg" alt="">
                <a class="start" href="javascript:;">Click "+" icon to start conversation</a>
            </div>
            <!-- start-conversation -->
            <div class="modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="msg-head">
                        <div class="row">

                            <div class="col-lg-9">
                                <div class="media">
                                    <span class="chat-icon" @click="hideMessageBox">
                                        <img class="img-fluid" src="@/assets/img/arroleftt.svg" alt="image title">
                                    </span>
                                    <div class="media-body">
                                        <h5 class="mt-0">
                                            {{ CurrentChat?.customer?.first_name+' '+CurrentChat?.customer?.last_name }}
                                            <span style="display:none;">[+1<span class="messagebox-phone">{{ CurrentChat?.customer?.phone }}</span>]</span>
                                            <br>
                                            <span v-text="CurrentChat?.chat_time"></span>
                                        </h5>
                                    </div>
                                    <div class="showright" title="More Info" bis_skin_checked="1" @click="showRightPanel">
                                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3">
                                <ul class="msgsetting">
                                    <li style="display:none;">
                                        <a href="#" data-toggle="modal" data-target="#exampleModal6"> <img class="img-fluid" src="@/assets/img/plus1.svg" alt="image title"> </a>
                                    </li>
                                    <li style="display:none;">
                                        <div class="navbar dropdown">
                                            <a class=" dropdown-toggle" href="#" role="button" id="dropdownMenuLink1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="img-fluid" src="@/assets/img/more.svg" alt="image title">
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink1">
                                                <a v-if="CurrentChat?.customer?.optin_status == 1" class="dropdown-item" @click="optUser(CurrentChat?.customer?.id, 'out')" href="javascript:;">Opt-Out</a>
                                                <a v-if="CurrentChat?.customer?.optin_status == 0" class="dropdown-item" @click="optUser(CurrentChat?.customer?.id, 'in')" href="javascript:;">Opt-In</a>
                                                <!-- <a class="dropdown-item" href="#">logout</a> -->
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="msg-body">
                            <ul>
                                <template v-for="message in CurrentChatMessages" :key="'message' + message.id" :id="'message' + message.id">
                                    <li v-if="message.message" :class="{
                                      'sender': message.is_from_customer,
                                      'repaly': !message.is_from_customer,
                                    }">
                                        <div v-if="message.is_from_customer" class="rlpyuser">
                                            <h6>{{ message.name_initials }}</h6>
                                        </div>
                                        <div class="text">
                                            <p>{{ message.message }}</p>
                                            <h5 class="datetime">{{ message.datetime }}</h5>
                                        </div>
                                        <div v-if="!message.is_from_customer" class="rlpyuser">
                                            <h6>{{ message.name_initials }}</h6>
                                        </div>
                                    </li>
                                    <li v-if="message.attachment_path" :class="{
                                      'sender': message.is_from_customer,
                                      'repaly': !message.is_from_customer,
                                    }">
                                        <div v-if="message.is_from_customer" class="rlpyuser">
                                            <h6>{{ message.name_initials }}</h6>
                                        </div>
                                        <div class="images">
                                            <div class="downloads" style="display:none;">
                                                <img class="img-fluid" src="@/assets/img/download.svg" alt="download">
                                            </div>
                                            <img class="img-fluid" :src="message.attachment_path" alt="image">
                                            <h5 class="datetime">{{ message.datetime }}</h5>
                                        </div>
                                        <div v-if="!message.is_from_customer" class="rlpyuser">
                                            <h6>{{ message.name_initials }}</h6>
                                        </div>
                                    </li>
                                </template>
                                <li id="chat_last_message" v-show="false"></li>
                            </ul>
                        </div>
                    </div>

                    <div class="send-box">
                        <div class="previewbox">
                            <ul>
                                <li>
                                    <p>
                                        <i class="fa fa-file" aria-hidden="true"></i>
                                        <span>File name</span>
                                        <span class="remove-btn"><img class="img-fluid" src="@/assets/img/x-circle.svg" alt="x"></span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-file" aria-hidden="true"></i>
                                        <span>Another File name</span>
                                        <span class="remove-btn"><img class="img-fluid" src="@/assets/img/x-circle.svg" alt="x"></span>
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        <i class="fa fa-file" aria-hidden="true"></i>
                                        <span>d0013970-tag_106801p000000000000028.zip</span>
                                        <span class="remove-btn"><img class="img-fluid" src="@/assets/img/x-circle.svg" alt="x"></span>
                                    </p>
                                </li>

                            </ul>
                        </div>

                        <form action="">
                            <div class="emonjis" data-emojiarea data-type="unicode" data-global-picker="false">
                                <div class="emoji-button" @click="_emoji_opened = !_emoji_opened">&#x1f604;</div>
                                <input type="text" v-model="formData.message" class="form-control messageInput" aria-label="message…" placeholder="Write message…" />
                                <EmojiPicker v-if="_emoji_opened" :native="true" @select="onSelectEmoji" />
                            </div>
                        </form>

                        <div class="send-btns">
                            <!-- template -->
                            <div class="select-temp shadow ">
                                <div class="temp-header">
                                    <div class="title-tem">
                                        <h3>Templates</h3>
                                        <div class="new">
                                            <div class="navbar dropdown">
                                                <a class=" dropdown-toggle" href="#" role="button" id="dropdownMenuLink3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img class="img-fluid" src="@/assets/img/setting2.svg" alt="image title">
                                                </a>

                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink3">
                                                    <a class="dropdown-item" href="#">All</a>
                                                    <a class="dropdown-item" href="#">Assigned to me</a>
                                                    <a class="dropdown-item" href="#">Unread</a>
                                                    <a class="dropdown-item" href="#">New </a>
                                                </div>
                                            </div>
                                            <a class="createnew" href="#">Create new</a>
                                        </div>
                                    </div>
                                    <div class="temp-search">
                                        <input type="text" class="form-control" id="inlineFormInputGroup" placeholder="Search" aria-label="search">
                                        <img class="img-fluid sea-icon" src="@/assets/img/search.svg" alt="image title">
                                    </div>
                                </div>
                                <div class="temp-body">
                                    <h4>my favorites</h4>
                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try. So I sat quietly.</p>
                                        </div>
                                    </div>

                                    <h4>recent used</h4>
                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try. So I sat quietly.</p>
                                        </div>
                                    </div>

                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try. So I sat</p>
                                        </div>
                                    </div>

                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try.</p>
                                        </div>
                                    </div>

                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try.</p>
                                        </div>
                                    </div>

                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try.</p>
                                        </div>
                                    </div>

                                    <div class="media">
                                        <i class="fa fa-star align-self-start mr-3" aria-hidden="true"></i>
                                        <div class="media-body">
                                            <h5 class="mt-0">Review template - 2023</h5>
                                            <p>I’m gon’ put her in a coma. You give a hundred reasons why, and you say you're really gonna try.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <!-- template -->

                            <div class="row">
                                <div class="col-9">
                                    <div class="attach">
                                        <div class="button-wrapper">
                                            <span class="label">
                                                <img class="img-fluid" src="@/assets/img/upload.svg" alt="image title"> Attach file
                                            </span>
                                            <input type="file" id="upload" @change="uploadFile" class="upload-box" placeholder="Upload File" aria-label="Upload File" accept="images/*" />
                                        </div>

                                        <div class="add-temp" style="display:none;">
                                            <a href="#"><img class="img-fluid" src="@/assets/img/template.png" alt="template"> Template</a>
                                        </div>
                                        <div class="add-apoint" style="display:none;">
                                            <a href="#" data-toggle="modal" data-target="#exampleModal4"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewbox="0 0 16 16" fill="none">
                                                    <path d="M8 16C3.58862 16 0 12.4114 0 8C0 3.58862 3.58862 0 8 0C12.4114 0 16 3.58862 16 8C16 12.4114 12.4114 16 8 16ZM8 1C4.14001 1 1 4.14001 1 8C1 11.86 4.14001 15 8 15C11.86 15 15 11.86 15 8C15 4.14001 11.86 1 8 1Z" fill="#7D7D7D" />
                                                    <path d="M11.5 8.5H4.5C4.224 8.5 4 8.276 4 8C4 7.724 4.224 7.5 4.5 7.5H11.5C11.776 7.5 12 7.724 12 8C12 8.276 11.776 8.5 11.5 8.5Z" fill="#7D7D7D" />
                                                    <path d="M8 12C7.724 12 7.5 11.776 7.5 11.5V4.5C7.5 4.224 7.724 4 8 4C8.276 4 8.5 4.224 8.5 4.5V11.5C8.5 11.776 8.276 12 8 12Z" fill="#7D7D7D" /></svg> Appoinment</a>
                                        </div>

                                    </div>
                                </div>

                                <div class="col-3">
                                    <div class="email-sends">
                                        <button type="button" class="smssend" role="button" :disabled="loading" @click="sendMessage">
                                            <img class="img-fluid" src="@/assets/img/plane.svg" alt="image title" v-if="!loading">
                                            <font-awesome-icon icon="fa-solid fa-spinner" spin v-else />
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- chat-right -->
        <div class="chat-right">
            <div class="modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-body rightPanel">
                        <div class="xright" @click="hideRightPanel">
                            <img class="img-fluid" src="@/assets/img/close.png" alt="close">
                        </div>
                        <!-- payment-btn -->
                        <div class="payment-btn" style="display:none;">
                            <ul>
                                <li>
                                    <a href="#"><img class="img-fluid" src="@/assets/img/doller.svg" alt="image title"> Payment</a>
                                </li>
                                <li>
                                    <a href="#"><img class="img-fluid" src="@/assets/img/plus1.svg" alt="image title"> Add Note</a>
                                </li>
                            </ul>
                        </div>
                        <!-- payment-btn -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>
                                    Opt-In?
                                    <span>
                                        <!-- Rounded switch -->
                                        <label class="switch">
                                            <input type="checkbox" class="switch-checkbox" :checked="CurrentChat?.customer?.optin_status == 1" @change="optUser(CurrentChat?.customer?.id)" />
                                            <span class="slider round"></span>
                                        </label>
                                    </span>
                                </h5>
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="!CurrentChat?.customer">
                            <div class="infor">
                                <h4>Select a Conversation</h4>
                            </div>
                        </div>
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>Info</h5>
                                <h4>Contact Information</h4>
                                <h2>{{ CurrentChat?.customer?.first_name+' '+CurrentChat?.customer?.last_name }}</h2>
                                <h2>+1<span class="messagebox-phone">{{ CurrentChat?.customer?.phone }}</span></h2>
                                <h2>{{ CurrentChat?.customer?.email }}</h2>
                                <div class="option" style="display:none;">
                                    <h3>Campaigns Opted In</h3>
                                    <h2>True</h2>
                                </div>
                                <div class="tabgs" style="display:none;">
                                    <h4>Tags
                                        <a href="#"><img class="img-fluid" src="@/assets/img/plus1.svg" alt="image title"></a>
                                    </h4>
                                    <ul>
                                        <li><a href="#">Tag <img class="img-fluid" src="@/assets/img/close.png" alt="image title"></a></li>
                                        <li><a href="#">Owners <img class="img-fluid" src="@/assets/img/close.png" alt="image title"></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>Tags <span><a href="javascript:;" @click="AddNewTag(CurrentChat?.id)"><i class="fa fa-plus"></i> Add tag</a></span></h5>
                                <div class="tabgs">
                                    <ul>
                                        <li v-for="mytag in MyTags" :value="mytag.id" v-bind:key="mytag.id"><a href="javascript:;" @click="deleteTag(CurrentChat?.id, mytag.id)">{{ mytag.name }} <img class="img-fluid" src="@/assets/img/close.png" alt="image title"></a></li>
                                    </ul>
                                </div>
                                <div class="noinfo" v-if="MyTags.length == 0">
                                    <p><img class="img-fluid" src="@/assets/img/alert-circle.svg" alt="image title"> No tags added</p>
                                </div>
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>Last Engagement</h5>
                                <h2>{{ CurrentChat?.latest_chat_message_sent_time }}</h2>
                                <!--<h3>{{ CurrentChat?.new_message }}</h3>-->
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>Last Contacted</h5>
                                <h2>{{ CurrentChat?.latest_chat_message_received_time }}</h2>
                                <!--<h3>{{ CurrentChat?.new_message }}</h3>-->
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>Appointment</h5>
                                <h2><a href="javascript:;" @click="createAppointment(CurrentChat?.customer)">Create an Appointment</a></h2>
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor campaign-list campaign-heightfix">
                                <h5>Campaigns <span v-if="AllCampaigns.length > 2"><a href="javascript:;" @click="showHideCampaigns">View More</a></span></h5>
                                <h3 v-for="Campaign in AllCampaigns" v-bind:key="Campaign.id" v-bind:value="Campaign.id"><a target="_blank" :href="'/text-campaign/'+Campaign.id">{{ Campaign.title }} {{ Campaign.id }}</a></h3>
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" v-if="CurrentChat?.customer">
                            <div class="infor">
                                <h5>Notes <span><a href="javascript:;" @click="AddNewNotes(CurrentChat?.id)"><i class="fa fa-plus"></i> Add note</a></span></h5>
                                <div class="chat-notes-main-area">
                                    <div class="chat-notes" v-for="Note in AllNotes" v-bind:key="Note.id" v-bind:value="Note.id">
                                        <hr>
                                        <h3>{{ Note.created_by_name }} <span>{{ Note.created_date }}<small title="Delete Note" @click="deleteNote(CurrentChat?.id, Note.id)">x</small></span></h3>
                                        <p>{{ Note.note }}</p>
                                    </div>
                                </div>
                                <div class="noinfo" v-if="AllNotes.length == 0">
                                    <p><img class="img-fluid" src="@/assets/img/alert-circle.svg" alt="image title"> No notes added</p>
                                </div>
                            </div>
                        </div>
                        <!-- info-tabs -->
                        <!-- info-tabs -->
                        <div class="info-tabs" style="display:none;">
                            <div class="infor nomore">
                                <div class="noinfo">
                                    <p><img class="img-fluid" src="@/assets/img/alert-circle.svg" alt="image title"> No more information</p>
                                </div>
                            </div>
                        </div>
                        <!-- info-tabs -->

                    </div>
                </div>
            </div>

        </div>
        <!-- chat-right -->
    </div>
    <!-- message-box -->

</div>
<!-- chat-area -->
<!-- newroom modal -->
<div class="modal fade newroom-modal" id="sendSMSFromMessage" tabindex="-1" role="dialog" aria-labelledby="sendSMSFromMessageLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="chatlist-area modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="sendSMSFromMessageLabel">Send SMS</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src="@/assets/img/close.svg" class="img-fluid" alt="image title">
                </button>
            </div>
            <div class="modal-body">
                <div class="contact-form">
                    <form>
                        <div class="row">
                            <div class="form-group col-md-12">
                                <label for="Customer">
                                    <p class="label-txt label-active select2-label-active">Select Customer</p>
                                    <div class="add-title" style="position: relative;">
                                        <input v-model="sendSMSValues.customer_name" type="text" class="form-control" placeholder="Search Customers..." id="appointment-customers-input" @click="openCustomerListBox" @keyup="searchCustomerListBox()" />
                                        <!-- <select class="input select2" v-model="newAppointment.customer_id" required>
                                    <option v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                        {{ Customer.first_name }} {{ Customer.last_name }}
                                    </option>
                                </select> -->
                                        <input type="hidden" v-model="sendSMSValues.sms_customer_id" />
                                        <div class="appointment-customers-list-box">
                                            <ul class="appointment-customers-list">
                                                <li class="appointment-customers-list-item" v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                                    <a class="appointment-customers-item" href="javascript:;" :data-id="Customer.id" :data-name="Customer.first_name+' '+Customer.last_name" @click="customerSelectId(Customer.first_name+' '+Customer.last_name, Customer.id)">{{ Customer.first_name+' '+Customer.last_name }}</a>
                                                </li>
                                            </ul>
                                            <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                            <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                        </div>
                                    </div>
                                </label>
                            </div>

                            <div class="form-group col-md-12">
                                <label for="message">
                                    <p class="label-txt label-active">Message</p>
                                    <textarea class="input" rows="5" id="sendSMSmessage" v-model="sendSMSValues.sms_message"></textarea>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>

            </div>

            <div class="modal-footer">
                <div class="text-center">
                    <button type="button" class="send-btn" @click="sendMessageToCustomer"> Send SMS</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- newroom modal -->
<!-- invite-Modal -->
<div class="modal fade" id="create-appointment" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel4">
                    Create an Appointment
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title" />
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-8">
                        <!-- create-meetings -->
                        <div class="create-meetings">
                            <div class="add-title">
                                <input v-model="newAppointment.title" type="text" class="form-control" placeholder="Title of appointment" />
                            </div>
                            <div class="add-title" style="position: relative;">
                                <input v-model="newAppointment.customer_name" type="text" class="form-control" placeholder="Customer..." disabled />
                                <input type="hidden" v-model="newAppointment.customer_id" />
                                <div class="appointment-customers-list-box">
                                    <ul class="appointment-customers-list">
                                        <li class="appointment-customers-list-item" v-for="Customer in AllCustomers" v-bind:key="Customer.id" v-bind:value="Customer.id">
                                            <a class="appointment-customers-item" href="javascript:;" :data-id="Customer.id" :data-name="Customer.first_name+' '+Customer.last_name" @click="customerSelectId(Customer.first_name+' '+Customer.last_name, Customer.id)">{{ Customer.first_name+' '+Customer.last_name }}</a>
                                        </li>
                                    </ul>
                                    <span class="appointment-customers-searching" style="display: none;">Searching...</span>
                                    <span class="appointment-customers-notfound" style="display: none;">No Customer found.</span>
                                </div>
                            </div>

                            <div class="one-group">
                                <p>Type of conference</p>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="containers active"><span class="label">In Person</span>
                                                <img class="img-fluid" src="@/assets/img/one.svg" alt="image title" />
                                                <input type="radio" name="radio" v-model="newAppointment.type" checked value="0" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <label class="containers"><span class="label">Virtual</span>
                                                <img class="img-fluid" src="@/assets/img/Virtual.svg" alt="image title" />
                                                <input type="radio" name="radio" v-model="newAppointment.type" value="1" />
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="calanders">
                                <p>Select a date</p>
                                <div class="calendar calendar-first" id="calendar_first">
                                    <div class="calendar_header">
                                        <h2></h2>
                                        <button class="switch-month switch-right">
                                            <img class="img-fluid" src="@/assets/img/arrowright.svg" alt="image title" />
                                        </button>
                                        <button class="switch-month switch-left">
                                            <img class="img-fluid" src="@/assets/img/arrowleft.svg" alt="image title" />
                                        </button>
                                    </div>
                                    <div class="add-title">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 8px; opacity: 0.8;"><i class="fa fa-calendar"></i></span>
                                        <input v-model="newAppointment.appointment_date" type="date" class="form-control datepicker" placeholder="Date of Appointment" min="1997-01-01" max="2030-12-31" />
                                    </div>
                                </div>

                                <!-- time-box -->
                                <div class="time-box mobile-view">
                                    <div class="times">
                                        <h4>Select time</h4>
                                        <h6>Time you’re available</h6>
                                        <h6>Current Time {{ getCurrentTime('time') }}</h6>
                                    </div>

                                    <div class="custom-control custom-radio custom-control-inline">
                                        <label class="containers active"><span class="label">Now ({{ getCurrentTime('now') }})</span>
                                            <input type="radio" name="radio1" checked />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>

                                    <div class="custom-control custom-radio custom-control-inline">
                                        <label class="containers"><span class="label">Custom time</span>
                                            <input type="radio" name="radio1" />
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <form>
                                        <div class="form-row">
                                            <div class="col">
                                                <select class="form-control">
                                                    <option>01</option>
                                                    <option>02</option>
                                                    <option>03</option>
                                                    <option>04</option>
                                                    <option>05</option>
                                                    <option>06</option>
                                                    <option>07</option>
                                                    <option>08</option>
                                                    <option>09</option>
                                                    <option>10</option>
                                                    <option>11</option>
                                                    <option>12</option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <select class="form-control">
                                                    <option>1</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </select>
                                            </div>
                                            <div class="col">
                                                <select class="form-control">
                                                    <option>AM</option>
                                                    <option>PM</option>
                                                </select>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- time-box -->
                            </div>
                        </div>

                        <!-- create-btms -->

                        <div class="create-btn">
                            <a href="#" data-dismiss="modal" aria-label="Close">Close</a>
                            <a class="create" href="javascript:;" @click="addAppointmentRequest">create</a>
                        </div>
                        <!-- create-btms -->
                    </div>

                    <div class="col-lg-4 desktop-view">
                        <!-- time-box -->
                        <div class="time-box">
                            <div class="times">
                                <h4>Select time</h4>
                                <h6>Time you’re available</h6>
                                <h6>Current Time {{ getCurrentTime('time') }}</h6>
                            </div>

                            <div class="custom-control custom-radio custom-control-inline">
                                <label class="containers active"><span class="label">Now ({{ getCurrentTime('now') }})</span>
                                    <input type="radio" name="radio1" checked v-model="newAppointment.timeType" value="now" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>

                            <div class="custom-control custom-radio custom-control-inline">
                                <label class="containers"><span class="label">Custom time</span>
                                    <input type="radio" name="radio1" v-model="newAppointment.timeType" value="custom" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <form>
                                <div class="form-row">
                                    <div class="col">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                        <select class="form-control" v-model="newAppointment.customTimeHours">
                                            <option value="">Hrs</option>
                                            <option value="01">01</option>
                                            <option value="01">02</option>
                                            <option value="01">03</option>
                                            <option value="01">04</option>
                                            <option value="01">05</option>
                                            <option value="01">06</option>
                                            <option value="01">07</option>
                                            <option value="01">08</option>
                                            <option value="01">09</option>
                                            <option value="01">10</option>
                                            <option value="01">11</option>
                                            <option value="01">12</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                        <select class="form-control" v-model="newAppointment.customTimeMinutes">
                                            <!-- <option v-for="i in 59" :key="i">{{ i+15 }}</option> -->
                                            <option value="">Min</option>
                                            <option value="00">00</option>
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="45">45</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <span style="position: absolute; z-index: 100; right: 8px; font-size: 20px; top: 3px; opacity: 0.8;"><i class="fa fa-angle-down"></i></span>
                                        <select class="form-control" v-model="newAppointment.customTimeAmPm">
                                            <option value="AM">AM</option>
                                            <option value="PM">PM</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- invite-Modal -->
<div class="modal fade" id="addNotesModal" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel4">
                    Add a note
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title" />
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea class="form-control" placholder="Add a note..." v-model="ChatNote" rows="7"></textarea>
                        </div>
                        <!-- create-btms -->

                        <div class="create-btn">
                            <a href="#" data-dismiss="modal" aria-label="Close">Close</a>
                            <a class="create" href="javascript:;" @click="addNoteRequest">Add</a>
                        </div>
                        <!-- create-btms -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- invite-Modal -->
<div class="modal fade" id="addTagsModal" tabindex="-1" aria-labelledby="exampleModalLabel4" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel4">
                    Add a tag
                </h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img class="img-fluid" src="@/assets/img/close.png" alt="image title" />
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-group">
                            <select class="form-control" v-model="ChatTag">
                                <option value="">Select a tag</option>
                                <option v-for="tag in AllTags" :value="tag.id" v-bind:key="tag.id">{{tag.name}}</option>
                            </select>
                        </div>
                        <!-- create-btms -->

                        <div class="create-btn">
                            <a href="#" data-dismiss="modal" aria-label="Close">Close</a>
                            <a class="create" href="javascript:;" @click="addTagRequest">Add</a>
                        </div>
                        <!-- create-btms -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<style>
.dropdown-menu .dropdown-item.active:before {
    content: "\2713";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 900;
    color: #6046FE;
}

.v3-emoji-picker {
    position: absolute;
    z-index: 1;
    bottom: 50px;
}

.v3-emoji-picker .v3-footer {
    display: none !important;
}

.dropdown-menu small {
    font-size: 12px;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
}

input:checked+.slider {
    background-color: #6046fe;
}

input:focus+.slider {
    box-shadow: 0 0 1px #6046fe;
}

input:checked+.slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.campaign-heightfix {
    min-height: 65px;
    max-height: 75px;
    overflow: hidden;
}
</style>

<script>
import $ from "jquery";
import 'select2';
import 'select2/dist/css/select2.css';
import {
    ref,
    onMounted,
    // watchEffect
} from "vue";
import axios from "axios";
import Pusher from "pusher-js";
import {
    useRoute
} from "vue-router";
import {
    useInfiniteScroll
} from '@vueuse/core'

import 'vue3-emoji-picker/css';
import EmojiPicker from 'vue3-emoji-picker';
// import vueMask from 'vue-jquery-mask';
// console.log(localStorage);
export default {
    components: {
        // vueMask,
        EmojiPicker
    },
    setup() {
        let base_url = "http://api.streamly.com/uploads/";
        const Params = useRoute();

        const _emoji_opened = ref(false);
        let inputCursor = ref(0);
        let unreadChatCount = ref('*');
        let unrepliedChatCount = ref('*');
        let unreadChatCountActive = ref(0);
        let unreadChatCountInActive = ref(0);
        let ChatNote = ref('Notes');
        let AllNotes = ref([]);
        let ChatTag = ref('Tags');
        let AllTagList = ref([]);
        let AllTags = ref([]);
        let MyTags = ref([]);
        let AllCampaigns = ref([]);
        let AllCustomers = ref([]);
        let AllActiveChats = ref([]);
        let AllInactiveChats = ref([]);
        let CurrentChatMessages = ref([]);
        let CurrentChat = ref([]);
        let limit_offset = ref(0);
        let ChatWindowActive = ref(null);
        let loading = ref(false);
        let activeChatsLoaded = ref(true);
        const ChatLoader = ref(false);

        const UrlParam = ref(Params.params.chatId);

        let sendSMSValues = ref({
            customer_name: '',
            sms_customer_id: 0,
            sms_message: '',
        });
        let newAppointment = ref({
            Id: null,
            title: null,
            type: null,
            customer_id: null,
            customer_name: null,
            appointment_date: null,
            appointment_time: null,
            notes: null,
            timeType: null,
            customTimeHours: null,
            customTimeMinutes: null,
            customTimeAmPm: null,
        });
        $(document).ready(function () {

        });

        //right panel
        const showRightPanel = () => {
            $(".chat-right").addClass('show');
        }
        const hideRightPanel = () => {
            $(".chat-right").removeClass('show');
        }

        const createAppointment = (Customer = null) => {
            // console.log();
            // return false;
            // editMode.value = false;
            newAppointment.value.Id = null;
            newAppointment.value.title = null;
            newAppointment.value.type = null;
            newAppointment.value.customer_id = Customer.id;
            newAppointment.value.customer_name = Customer.first_name + ' ' + Customer.last_name;
            newAppointment.value.appointment_date = null;
            newAppointment.value.timeType = null;
            newAppointment.value.customTimeHours = "";
            newAppointment.value.customTimeMinutes = "";
            newAppointment.value.customTimeAmPm = "AM";
            newAppointment.value.notes = null;
            $("#create-appointment").modal("show");
        };
        const getCurrentTime = (timetype = null) => {
            var todayDate = new Date();
            if (timetype == 'now') {
                var hours = todayDate.getHours();
                var minutes = todayDate.getMinutes();
                hours = hours % 12;
                hours = hours ? hours : 12; // the hour '0' should be '12'
                minutes = minutes < 10 ? '0' + minutes : minutes;
                var ampm = todayDate.getHours() >= 12 ? 'pm' : 'am';
                return hours + ':' + minutes + ' ' + ampm;
            } else {
                return todayDate.getHours() + ":" + todayDate.getMinutes() + ":" + todayDate.getSeconds();
            }
        };
        const addAppointmentRequest = async () => {
            let addFormData = new FormData()
            addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            addFormData.append('location_id', localStorage.getItem('location_id'));
            addFormData.append('title', newAppointment.value.title);
            addFormData.append('type', newAppointment.value.type);
            addFormData.append('customer_id', newAppointment.value.customer_id);
            addFormData.append('appointment_date', newAppointment.value.appointment_date);
            addFormData.append('timeType', newAppointment.value.timeType);
            addFormData.append('customTimeHours', newAppointment.value.customTimeHours);
            addFormData.append('customTimeMinutes', newAppointment.value.customTimeMinutes);
            addFormData.append('customTimeAmPm', newAppointment.value.customTimeAmPm);
            addFormData.append('notes', newAppointment.value.notes);
            // console.log(teamuser_image);
            await axios
                .post(
                    axios.defaults.baseUrl + "appointments/add",
                    addFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.toast.fire({
                        icon: response.data.status,
                        title: response.data.message,
                    });
                    // getAppointmentList();
                    closeModal();
                    newAppointment.value = '';
                    location.assign('/appointments');
                });
        }
        // $(document).on("keydown keypress mousemove", ".messageInput", function() {
        // inputCursor = $(this).caret().start;
        // console.log(inputCursor);
        // let abc = formData.message.selectionStart;
        // console.log(abc);
        // });
        const unReadRepliedChatsCount = async () => {
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "unreadChatcount",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    unreadChatCount.value = response.data.data.unread_count;
                    unrepliedChatCount.value = response.data.data.unreplied_count;
                    unreadChatCountActive.value = response.data.data.unread_count_active;
                    unreadChatCountInActive.value = response.data.data.unread_count_inactive;
                    if (response.data.data.unread_count > 0) {
                        // console.log(response.data.data.unread_count);
                        $('.menu ul li a i.fa-beat').show();
                        // var baseUrl = window.location.origin;
                    }else{
                        $('.menu ul li a i.fa-beat').hide();
                    }
                })
                .catch((error) => {
                    console.log(error);
                    // window.showHideMainLoader(false);
                    //showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        async function starClick(chat_id = 0, type = null) {
            if (chat_id > 0 && type != null) {
                window.showHideMainLoader(true);
                let pinnedFormData = new FormData();
                pinnedFormData.append('chat_id', chat_id);
                await axios
                    .post(
                        axios.defaults.baseUrl + "pinConversation",
                        pinnedFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        window.showHideMainLoader(false);
                        // console.log(response.data.data);
                        if (response.data.data.is_pinned == 1) {
                            if (!$('.stars-' + chat_id + ' a').hasClass('important')) {
                                $('.stars-' + chat_id + ' a').addClass('important');
                            }
                            if (type == 'inactive') {
                                const chat = AllInactiveChats.value.find(chat => chat.id == chat_id)
                                let arrIndex = $('.chat-no-' + chat.id).attr('data-index');
                                if (arrIndex > 0) {
                                    AllInactiveChats.value.splice(arrIndex, 1);
                                    AllInactiveChats.value.unshift(chat);
                                }
                            } else {
                                const chat = AllActiveChats.value.find(chat => chat.id == chat_id)
                                let arrIndex = $('.chat-no-' + chat.id).attr('data-index');
                                if (arrIndex > 0) {
                                    AllActiveChats.value.splice(arrIndex, 1);
                                    AllActiveChats.value.unshift(chat);
                                }
                            }
                        } else if (response.data.data.is_pinned == 0) {
                            $('.stars-' + chat_id + ' a').removeClass('important');
                        }
                    });
            }
        }
        const phoneFormat = async () => {
            $(".messagebox-phone").text(function (i, text) {
                text = text.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
                return text;
            });
        }
        useInfiniteScroll(
            ChatWindowActive,
            () => {
                // load more
                if (!ChatLoader.value) {
                    if (!activeChatsLoaded.value) {
                        getChats(Params.params.type, limit_offset);
                        // console.log('here');
                    }
                }
            }, {
                distance: 10
            }
        )
        const getCustomers = async () => {
            window.showHideMainLoader(true);
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "customers/appointment",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    AllCustomers.value = response.data.data.allCustomers;
                    // datatableInit();
                    window.showHideMainLoader(false);
                    setTimeout(() => {
                        // datatableInit();
                        // $("#dataTableBody").show();
                        $('.select2').select2({
                            dropdownParent: $("#sendSMSFromMessage"),
                        });
                    }, 500);
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    //showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const customerSelectId = async (selected_customer_name = null, selected_customer_id = null) => {
            sendSMSValues.value.customer_name = selected_customer_name;
            sendSMSValues.value.sms_customer_id = selected_customer_id;
            $('.appointment-customers-list-box').hide();
        }
        const openCustomerListBox = async () => {
            if (!$('.appointment-customers-list-box').is(':visible')) {
                $('.appointment-customers-list-box').show();
            } else {
                $('.appointment-customers-list-box').hide();
            }
        }
        const searchCustomerListBox = async () => {
            let search_param = sendSMSValues.value.customer_name;
            if (search_param.length >= 2) {
                $('.appointment-customers-notfound').hide();
                $('.appointment-customers-list').hide();
                $('.appointment-customers-searching').show();
                const getFormData = new FormData();
                getFormData.append('search_param', search_param);
                getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                getFormData.append('location_id', localStorage.getItem('location_id'));
                await axios
                    .post(axios.defaults.baseUrl + "customers/appointment",
                        getFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem('auth_token'),
                            },
                        }
                    )
                    .then((response) => {
                        AllCustomers.value = response.data.data.allCustomers;
                        // console.log(response.data.data.allCustomers);
                        $('.appointment-customers-searching').hide();
                        if (!response.data.data.no_customers) {
                            $('.appointment-customers-list').show();
                        } else {
                            $('.appointment-customers-notfound').show();
                        }
                    })
            }
        }
        const getChats = async (chat_type = null, limit_offset = 0) => {
            $('.loading-chat-list').show();
            ChatLoader.value = true;
            $('.no-chat-list-active,.no-chat-list-inactive').hide();
            // window.showHideMainLoader(true);
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            // getFormData.append('chat_type', chat_type);
            await axios
                .post(
                    axios.defaults.baseUrl + "getAllChats/" + chat_type + "/" + limit_offset.value,
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    ChatLoader.value = false;
                    $('.loading-chat-list').hide();
                    // AllActiveChats.value = response.data.data.allActiveChats;
                    AllInactiveChats.value = response.data.data.allInactiveChats;
                    AllActiveChats.value.push(...response.data.data.allActiveChats);
                    limit_offset.value = Number(limit_offset.value) + Number(response.data.data.allActiveChats.length);
                    $('.no-chat-list-active,.no-chat-list-inactive').show();
                    activeChatsLoaded.value = response.data.data.activeChatsLoaded;
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    //showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const getChatMessages = async (chat_id = null, type = null) => {
            formData.value.chat_id = chat_id;
            window.showHideMainLoader(true);
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "getAllChatMessages/" + chat_id,
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    $('.conversation').hide();
                    CurrentChatMessages.value = response.data.data.allMessages;
                    CurrentChat.value = response.data.data.currentChat;
                    let chat = [];
                    if (type == 'active') {
                        chat = AllActiveChats.value.find(chat => chat.id == chat_id);
                    } else {
                        chat = AllInactiveChats.value.find(chat => chat.id == chat_id);
                    }
                    // console.log(chat.is_read);
                    chat.is_read = 1;
                    chat.new_message_count = 0;
                    // datatableInit();
                    window.showHideMainLoader(false);
                    setTimeout(() => {
                        scrollToElement();
                        unReadRepliedChatsCount();
                    }, 500);
                    $('.all-list .media').removeClass('active');
                    $('.chat-no-' + chat_id + ' .media').addClass('active');
                    getNotesList(chat_id);
                    getTagList(chat_id);
                    getCustomerCampaignsList(chat_id);
                    setTimeout(() => {
                        phoneFormat();
                    }, 3000);
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    //showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }
        const optUser = async (user_id = null, opt_type = 'out') => {
            window.showHideMainLoader(true);
            // console.log(formData);
            setTimeout(() => {
                if ($('.switch-checkbox').is(':checked')) {
                    opt_type = 'in';
                }
                optUserFunction(user_id, opt_type);
            }, 200);
        }
        const optUserFunction = async (user_id = null, opt_type = null) => {
            if (user_id != null && opt_type != null) {
                // console.log('here');
                let optUserFormData = new FormData();
                optUserFormData.append('user_id', user_id);
                optUserFormData.append('opt_type', opt_type);
                optUserFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                optUserFormData.append('location_id', localStorage.getItem('location_id'));
                await axios
                    .post(
                        axios.defaults.baseUrl + "customers/optUser",
                        optUserFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        window.showHideMainLoader(false);
                        showToast(response.data.status, response.data.message);
                        // if(opt_type){
                        //     console.log('asd');
                        // }
                optUserFormData.append('user_id', user_id);
                        location.assign(window.location.origin+'/inbox/'+Params.params.type+'/'+formData.value.chat_id); //-----remove later
                    })
                    .catch((error) => {
                        // console.log(error);
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
            }
        }
        const addClass = (elementName, className) => {
            $(elementName).addClass(className);
        }
        const removeClass = (elementName, className) => {
            $(elementName).removeClass(className);
        }
        const showToast = (toastIcon, toastMessage) => {
            window.toast.fire({
                icon: toastIcon,
                title: toastMessage,
            });
        }
        const sendSMSFromMessage = () => {
            $("#sendSMSFromMessage").modal("show");
        }
        const sendMessageToCustomer = async () => {
            if (sendSMSValues.value.sms_customer_id == '') {
                showToast('error', 'Please select a customer');
                return false;
            }
            if (sendSMSValues.value.sms_message == '') {
                showToast('error', 'Message cannot be empty');
                return false;
            }
            window.showHideMainLoader(true);
            const postFormData = new FormData();
            postFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            postFormData.append('location_id', localStorage.getItem('location_id'));
            postFormData.append('customer_id', sendSMSValues.value.sms_customer_id);
            postFormData.append('message', sendSMSValues.value.sms_message);
            await axios
                .post(
                    axios.defaults.baseUrl + "sendMessageToCustomer",
                    postFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem('auth_token'),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    showToast(response.data.status, response.data.message);
                    location.assign('/inbox/all/' + response.data.data.chat_id);
                })
                .catch((error) => {
                    console.log(error);
                    window.showHideMainLoader(false);
                    //showToast(error.response.data.status, error.response.data.message);
                    return false;
                });
        }

        const AddNewNotes = () => {
            ChatNote.value = '';
            $('#addNotesModal').modal('show');
        }
        const addNoteRequest = async () => {
            // console.log(ChatNote.value);
            if (!ChatNote.value) {
                showToast('warning', 'Please type a note to add!');
            } else {
                let addFormData = new FormData();
                addFormData.append('chat_id', CurrentChat.value.id);
                addFormData.append('note', ChatNote.value);
                // console.log(teamuser_image);
                await axios
                    .post(
                        axios.defaults.baseUrl + "chatNotes/add",
                        addFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        showToast('success', 'Note Added!');
                        getNotesList(CurrentChat.value.id);
                        closeModal();
                        ChatNote.value = '';
                        // location.assign('/appointments');
                    });
            }
        }
        const getNotesList = async (chat_id = null) => {
            // console.log(chat_id);
            await axios
                .get(
                    axios.defaults.baseUrl + "chatNotes/get/" + chat_id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    AllNotes.value = response.data.data;
                });
        }
        const deleteNote = async (chat_id = null, note_id = null) => {
            window.showHideMainLoader(true);
            // console.log(chat_id);
            await axios
                .get(
                    axios.defaults.baseUrl + "chatNotes/delete/" + chat_id + "/" + note_id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status == 'success') {
                        getNotesList(chat_id);
                        showToast('success', 'Note deleted!');
                    } else {
                        showToast('error', 'Something went wrong');
                        window.showHideMainLoader(false);
                    }

                });
        }

        const getAllTagList = async () => {
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "chatTags/get",
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response.data.data);
                    window.showHideMainLoader(false);
                    AllTagList.value = response.data.data;
                });
        }
        const AddNewTag = () => {
            ChatTag.value = '';
            $('#addTagsModal').modal('show');
        }
        const addTagRequest = async () => {
            // console.log(ChatTag.value);
            if (!ChatTag.value) {
                showToast('warning', 'Please select a tag to add!');
            } else {
                window.showHideMainLoader(true);
                let addFormData = new FormData();
                addFormData.append('chat_id', CurrentChat.value.id);
                addFormData.append('tag_id', ChatTag.value);
                addFormData.append('dealer_id', localStorage.getItem('dealer_id'));
                addFormData.append('location_id', localStorage.getItem('location_id'));
                // console.log(teamuser_image);
                await axios
                    .post(
                        axios.defaults.baseUrl + "chatTags/add",
                        addFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response);
                        showToast('success', 'Tag Added!');
                        getTagList(CurrentChat.value.id);
                        closeModal();
                        ChatTag.value = '';
                        window.showHideMainLoader(false);
                    });
            }
        }
        const getTagList = async (chat_id = null) => {
            const getFormData = new FormData();
            getFormData.append('dealer_id', localStorage.getItem('dealer_id'));
            getFormData.append('location_id', localStorage.getItem('location_id'));
            await axios
                .post(
                    axios.defaults.baseUrl + "chatTags/get/" + chat_id,
                    getFormData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    // console.log(response.data.data);
                    window.showHideMainLoader(false);
                    AllTags.value = response.data.data.other_tags;
                    MyTags.value = response.data.data.my_tags;
                });
        }
        const deleteTag = async (chat_id = null, tag_id = null) => {
            window.showHideMainLoader(true);
            // console.log(chat_id);
            await axios
                .get(
                    axios.defaults.baseUrl + "chatTags/delete/" + chat_id + "/" + tag_id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    if (response.data.status == 'success') {
                        getTagList(chat_id);
                        showToast('success', 'Tag deleted!');
                    } else {
                        showToast('error', 'Something went wrong');
                        window.showHideMainLoader(false);
                    }

                });
        }

        const getCustomerCampaignsList = async (chat_id = null) => {
            // console.log(chat_id);
            await axios
                .get(
                    axios.defaults.baseUrl + "chatCampaigns/get/" + chat_id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("auth_token"),
                        },
                    }
                )
                .then((response) => {
                    window.showHideMainLoader(false);
                    AllCampaigns.value = response.data.data;
                });
        }
        // Open Modal
        const openModal = () => {

        };
        // Close Modal
        const closeModal = () => {
            $("#create-appointment").modal("hide");
            $('#addNotesModal').modal('hide');
            $('#addTagsModal').modal('hide');
        };
        const ChatListFilterSearch = (event) => {
            // console.log(event.target.value);
            let search_query = event.target.value.toLowerCase();
            $('.chat-list-empty-active').hide();
            $('.chat-list-empty-inactive').hide();
            if (search_query.length > 0) {
                let chat_count = 0;
                let tab_type = '';
                if ($('#Open-tab').hasClass('active')) {
                    tab_type = 'active';
                    // console.log(tab_type);
                } else {
                    tab_type = 'inactive';
                    // console.log(tab_type);
                }
                $('.all-chat-list-' + tab_type).each(function () {
                    let chat_id = $(this).children('.media').data('chat_id');
                    let customer_name = $(this).children('.media').data('customer_name');
                    if (typeof customer_name !== "undefined") {
                        if (customer_name.toLowerCase().search(search_query) < 0) {
                            $('.chat-no-' + chat_id).hide();
                        } else {
                            $('.chat-no-' + chat_id).show();
                            chat_count++;
                        }
                    }
                });
                if (chat_count == 0) {
                    $('.chat-list-empty-' + tab_type).show();
                }
            } else {
                $('.all-list').show();
            }
        }
        const scrollToElement = () => {
            // const last_message =  current_chat_messages.value.slice(-1)[0];

            // if (last_message){

            const el = document.getElementById("chat_last_message");

            if (el) {
                // console.log($('.msg-body ul')[0].scrollHeight);

                // Use el.scrollIntoView() to instantly scroll to the element
                // el.scrollIntoView({ behavior: "smooth" });
                $('.message-box .modal-body').scrollTop($('.msg-body ul')[0].scrollHeight);
                $('.message-box .modal-body.rightPanel').scrollTop(0);

                // }
            }
        };
        /*--------------------SEND MESSAGE---------------------*/
        const formData = ref({
            'chat_id': Params.params.chat_id,
            // 'message_type': messageType.MESSAGE,
            'message': '',
            'attachment_path': '',
        });

        const attachment = ref({
            // 'attachment_path': '',
            'attachment_name': '',
            'attachment_type': '',
            'attachment_content': '',
        });

        const uploadFile = function (event) {
            formData.value.attachment_path = event.target.files[0];

            if (formData.value.attachment_path) {
                attachment.value.attachment_name = formData.value.attachment_path.name
                if (formData.value.attachment_path.type.includes('image')) {
                    attachment.value.attachment_type = 'image';
                } else {
                    attachment.value.attachment_type = 'file';
                }
                readFile(formData.value.attachment_path)

            }
            // console.log('Before Send: '+formData.attachment_path);
            // sendPostRequest('sendMessage', formData);
        }

        const readFile = function (file) {
            var reader = new FileReader();
            reader.onloadend = function () {
                attachment.value.attachment_content = reader.result
            }
            if (file) {
                reader.readAsDataURL(file);
            }
            sendMessage();

        }
        const removeFile = function () {
            formData.value.attachment_path = '';
            attachment.value.attachment_name = '';
            attachment.value.attachment_type = '';
            attachment.value.attachment_content = '';
        }

        function resetFormDate() {
            removeFile();
            formData.value.message = '';
        }

        function showHideCampaigns() {
            if ($('.campaign-list').hasClass('campaign-heightfix')) {
                $('.campaign-list').removeClass('campaign-heightfix');
                $('.campaign-list h5 span a').html('View Less');
            } else {
                $('.campaign-list').addClass('campaign-heightfix');
                $('.campaign-list h5 span a').html('View More');
            }
        }
        $(document).on('keydown keypress mouseup mousedown', '.messageInput', function (event) {
            let keycode = (event.keyCode ? event.keyCode : event.which);
            setTimeout(() => {
                inputCursor = event.target.selectionStart;
                // console.log(inputCursor);
            }, 200);

            if (keycode == '13') {
                event.preventDefault();
                sendMessage();
            }
        });
        async function sendMessage() {
            // console.log(formData);
            if (formData.value.message || formData.value.attachment_path) {
                // console.log('here');
                let sendMessageFormData = new FormData();
                sendMessageFormData.append('chat_id', formData.value.chat_id);
                sendMessageFormData.append('message', formData.value.message);
                sendMessageFormData.append('attachment_path', formData.value.attachment_path);
                /**/
                await axios
                    .post(
                        axios.defaults.baseUrl + "sendMessage",
                        sendMessageFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        // console.log(response.data);
                        CurrentChatMessages.value.push(response.data.data);
                        const chat = AllActiveChats.value.find(chat => chat.id == formData.value.chat_id)
                        chat.chat_time = response.data.data.chat_time;
                        chat.new_message = response.data.data.new_message;
                        chat.is_read = 1;
                        chat.new_message_count = 0;
                        let arrIndex = $('.chat-no-' + chat.id).attr('data-index');
                        // console.log(arrIndex);
                        if (arrIndex > 0) {
                            AllActiveChats.value.splice(arrIndex, 1);
                            AllActiveChats.value.unshift(chat);
                        }
                        setTimeout(() => {
                            scrollToElement();
                        }, 500);
                        resetFormDate();
                        // location.reload(); //-----remove later
                        //**** send message change time ****/
                        CurrentChat.value.latest_chat_message_sent_time = response.data.data.latest_chat_message_sent_time;
                    })
                    .catch((error) => {
                        // console.log(error);
                        window.showHideMainLoader(false);
                        showToast(error.response.data.status, error.response.data.message);
                        return false;
                    });
                /**/
            }
        }
        async function receiveMessage(payload = null) {
            // console.log(formData);
            if (payload.data.message_id && payload.data.target_id) {
                // console.log('here');
                let receiveMessageFormData = new FormData();
                receiveMessageFormData.append('chat_id', payload.data.target_id);
                receiveMessageFormData.append('message_id', payload.data.message_id);
                receiveMessageFormData.append('opened_chat_id', formData.value.chat_id);
                await axios
                    .post(
                        axios.defaults.baseUrl + "receiveLastMessage",
                        receiveMessageFormData, {
                            headers: {
                                Authorization: "Bearer " + localStorage.getItem("auth_token"),
                            },
                        }
                    )
                    .then((response) => {
                        console.log(response.data);
                        const chat = AllActiveChats.value.find(chat => chat.id == payload.data.target_id);
                        chat.chat_time = response.data.data.chat_time;
                        chat.new_message = response.data.data.new_message;
                        chat.is_read = response.data.data.is_read;
                        chat.new_message_count = response.data.data.new_message_count;
                        if (payload.data.target_id == formData.value.chat_id) {
                            CurrentChatMessages.value.push(response.data.data);
                            chat.is_read = 1;
                            chat.new_message_count = 0;
                            setTimeout(() => {
                                scrollToElement();
                            }, 500);
                        }
                        let arrIndex = $('.chat-no-' + chat.id).attr('data-index');
                        console.log(arrIndex);
                        if (arrIndex > 0) {
                            AllActiveChats.value.splice(arrIndex, 1);
                            AllActiveChats.value.unshift(chat);
                        }
                        // resetFormDate();
                        // location.reload(); //-----remove later
                        //**** receive message change time ****/
                        CurrentChat.value.latest_chat_message_received_time = response.data.data.latest_chat_message_received_time;
                    });
            }
        }
        /*--------------------SEND MESSAGE---------------------*/
        /*----Pusher Message Start----*/
        const pusher = new Pusher('3f202806c5b779461f0b', {
            cluster: 'ap2',
        });
        const pusher_channel_name = "streamly-channel-" + localStorage.getItem('dealer_id');
        // const pusher_event = "chat-message-" + Params.params.chat_id;
        const pusher_event_open = "chat-message-open";
        const channel = pusher.subscribe(pusher_channel_name);
        // channel.bind(pusher_event_open, (payload) => {
        //     console.log(payload);
        //     console.log('here to do stuff');
        // });
        /*----Pusher Message End----*/
        const onSelectEmoji = (emoji) => {
            // console.log(emoji.i);
            // inputCursor
            var a = formData.value.message;
            var b = emoji.i;
            var position = inputCursor;
            var output = [a.slice(0, position), b, a.slice(position)].join('');
            // console.log(output);
            formData.value.message = output;
        }
        //message
        function showMessageBox() {
            $(".message-box").addClass('showbox');
            return false;
        }

        function hideMessageBox() {
            $(".message-box").removeClass('showbox');
            return false;
        }

        onMounted(() => {
            // console.log(Params.params.chat_id);
            /*----Pusher Message Start----*/
            // channel.bind(pusher_event, (payload) => {
            //     receiveMessage(payload);
            // });
            channel.bind(pusher_event_open, (payload) => {
                console.log(payload);
                receiveMessage(payload);
                // AllActiveChats.value.unshift('<a>New element</a>');
                // console.log('here to do stuff');
                // let received_chat_id = payload.data.target_id;
                // const received_chat = AllActiveChats.value.find(received_chat => received_chat.id == received_chat_id)
                // received_chat.chat_time = response.data.data.chat_time;
                // received_chat.new_message = response.data.data.new_message;
            });
            // scrollToElement();
            // console.log("Channel: " + pusher_channel_name);
            // console.log("Event: " + pusher_event);
            // console.log("Open Event: " + pusher_event_open);
            // console.log("Pusher Initialized");
            /*----Pusher Message End----*/
            getCustomers();
            getChats(Params.params.type, limit_offset);
            if (typeof Params.params.chat_id === "undefined") {
                console.log('chat-screen');
            } else if (Params.params.chat_id != '') {
                getChatMessages(Params.params.chat_id);
                getNotesList(Params.params.chat_id);
            }
            if (Params.params.type == '') {
                Params.params.type = 'all';
            }
            getAllTagList();
            unReadRepliedChatsCount();
            window.showHideMainLoader(false);
        });
        /*watchEffect(() => {
            UrlParam.value = Params.params.type;
            // console.log('Chat ID changed to:', chatId.value);
            getChats(UrlParam.value, limit_offset);
            window.showHideMainLoader(false);
        });*/

        return {
            openModal,
            closeModal,
            base_url,
            AllCustomers,
            removeClass,
            addClass,
            showToast,
            sendSMSFromMessage,
            sendSMSValues,
            sendMessageToCustomer,
            getChats,
            getChatMessages,
            AllActiveChats,
            AllInactiveChats,
            CurrentChatMessages,
            CurrentChat,
            ChatListFilterSearch,
            scrollToElement,
            uploadFile,
            sendMessage,
            formData,
            resetFormDate,
            removeFile,
            loading,
            Params,
            unReadRepliedChatsCount,
            unreadChatCount,
            unrepliedChatCount,
            unreadChatCountActive,
            unreadChatCountInActive,

            inputCursor,
            onSelectEmoji,
            _emoji_opened,
            limit_offset,
            ChatWindowActive,
            optUser,
            optUserFunction,
            activeChatsLoaded,

            starClick,

            customerSelectId,
            openCustomerListBox,
            searchCustomerListBox,

            createAppointment,
            newAppointment,
            getCurrentTime,
            addAppointmentRequest,

            AddNewNotes,
            ChatNote,
            addNoteRequest,
            getNotesList,
            deleteNote,
            AllNotes,

            AddNewTag,
            ChatTag,
            addTagRequest,
            getTagList,
            deleteTag,
            AllTags,
            MyTags,
            AllTagList,
            getAllTagList,

            AllCampaigns,
            getCustomerCampaignsList,
            showHideCampaigns,

            showMessageBox,
            hideMessageBox,
            showRightPanel,
            hideRightPanel,

            UrlParam,
        };
    }
};
</script>
